<template>
  <div>
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div
      class="one_option"
      :class="{ checked: isInArray(answerList, i.label) }"
      v-for="i in options"
      :key="i.sort"
    >
      <div class="option" @click="chooseQuestion(i)">
        {{ i.sort }}
      </div>
      <div class="option_text" v-html="i.label"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "judge",
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    historyAnswer: {
      type:String,
      default:''
    }
  },
  methods: {
    // 判断是否已存在在数组中
    isInArray(arr, item) {
      return arr.some((e) => e == item);
    },
    // 选择正确答案
    chooseQuestion(item) {
      this.answerList = [];
      this.answerList.push(item.label);
      let answer = {};
      let storageAnswer = {};
      answer[item.questionId] = item.value;
      storageAnswer[item.questionId] = item.label;
      this.$emit("chooseAnswer", answer, storageAnswer)
    },
  },
  data() {
    return {
      options: [
        {
          questionId: this.question.questionId,
          sort: "A",
          label: "对",
          value: true,
        },
        {
          questionId: this.question.questionId,
          sort: "B",
          label: "错",
          value: false,
        },
      ],
      answerList: [],
    };
  },
  created() {
    this.answerList = this.historyAnswer.split(',')
    if(this.historyAnswer !== '') {
      let value = this.answerList[0];
      let answer = {};
      let storageAnswer = {};
      answer[this.question.questionId] = value == '对' ?  true : false;
      storageAnswer[this.question.questionId] = value;
      this.$emit("chooseAnswer", answer, storageAnswer)
    }
  }
};
</script>

<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    // img {
    //   margin: 0 5px !important;
    //   max-height: 30px !important;
    // }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: top;
  }
}
.one_option {
  margin-bottom: 15px;
  &.checked {
    .option {
      background: #e5f1ff;
      color: #0d79ff;
      border-color: #e5f1ff !important;
    }
    .option_text {
      color: #0d79ff;
    }
  }
  .option {
    display: inline-block;
    width: 38px;
    text-align: center;
    line-height: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    cursor: pointer;
  }
  .option_text {
    display: inline-block;
    margin-left: 16px;
    img {
      margin: 0 5px !important;
      max-height: 30px !important;
    }
  }
}
</style>