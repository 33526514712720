<template>
  <div>
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div
      class="one_option"
      :class="{ checked: isInArray(answerList, i.sort) }"
      v-for="i in question.optionList"
      :key="i.sort"
    >
      <div class="option" @click="chooseQuestion(i)">
        {{ i.sort }}
      </div>
      <div class="option_text" v-html="i.value"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "multiple",
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    historyAnswer: {
      type: String,
      default: "",
    },
  },
  methods: {
    // 判断是否已存在在数组中
    isInArray(arr, item) {
      return arr.some((e) => e === item);
    },
    // 选择正确答案
    chooseQuestion(item) {
      if (this.isInArray(this.answerList, item.sort)) {
        let index = this.answerList.indexOf(item.sort);
        this.answerList.splice(index, 1);
      } else {
        this.answerList.push(item.sort);
      }
      let sortArr = [...this.answerList];
      let answer = {};
      answer[item.questionId] = sortArr.join(",");
      this.$emit("chooseAnswer", answer);
    },
  },
  data() {
    return {
      answerList: [],
    };
  },
  created() {
    // this.answerList = this.historyAnswer.split(",");
    if (this.historyAnswer !== '') {
      this.answerList = this.historyAnswer.split(",");
      let sortArr = [...this.answerList];
      let answer = {};
      answer[this.question.questionId] = sortArr.join(",");
      this.$emit("chooseAnswer", answer);
    }
  },
};
</script>

<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    // img {
    //   margin: 0 5px !important;
    //   max-height: 30px !important;
    // }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: top;
  }
}
.one_option {
  margin-bottom: 15px;
  &.checked {
    .option {
      background: #e5f1ff;
      color: #0d79ff;
      border-color: #e5f1ff !important;
    }
    .option_text {
      color: #0d79ff;
    }
  }
  .option {
    display: inline-block;
    width: 38px;
    text-align: center;
    line-height: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    cursor: pointer;
  }
  .option_text {
    display: inline-block;
    margin-left: 16px;
    img {
      margin: 0 5px !important;
      max-height: 30px !important;
    }
  }
}
</style>