import { render, staticRenderFns } from "./supply.vue?vue&type=template&id=a8b81e78&scoped=true&"
import script from "./supply.vue?vue&type=script&lang=js&"
export * from "./supply.vue?vue&type=script&lang=js&"
import style0 from "./supply.vue?vue&type=style&index=0&id=a8b81e78&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b81e78",
  null
  
)

export default component.exports