<template>
  <div class="supply_content_sty">
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div class="question_answer_content_sty">
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="answer"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answer:''
    };
  },
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    historyAnswer:{
      type:String,
      default:''
    }
  },
  watch: {
    answer: function() {
        this.$emit('updataBlankAnswer',this.answer,this.question.questionId)
    }
  },
  computed: {},
  created() {
    this.answer = this.historyAnswer
  },
};
</script>


<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    vertical-align: middle;
    /deep/ img {
      margin: 0 5px !important;
      //   width: 100%;
      //   max-height: 60px !important;
    }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: top;
  }
}
.question_answer_content_sty {
  width: 100%;
}
</style>