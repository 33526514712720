<template>
  <div class="supply_content_sty">
    <div class="stem">
      <span class="index">{{ idx }}.</span>
      <p class="question_title" v-html="question.question"></p>
      <b class="question_score">{{ question.score }}分</b>
    </div>
    <div class="question_answer_content_sty">
      <div
        class="question_answer_content_item_sty"
        v-for="(i, index) in answerList"
        :key="index"
      >
        <div class="question_answer_content_item_index_sty">
          {{ index + 1 }}
        </div>
        <div class="question_answer_content_item_input_sty">
          <el-input
            placeholder="请输入答案"
            v-model="i[`answer${index + 1}`]"
            clearable
          >
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answerList: [],
      isShowClean: false,
      value: "",
    };
  },
  props: {
    // 索引
    idx: {
      type: Number,
      default: null,
    },
    // 题目对象
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    historyAnswer: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    answerList: {
      deep: true,
      handler: function () {
        console.log(this.answerList,'填空题答案')
        this.$emit("updataAnswer", this.answerList, this.question.questionId);
      },
    },
  },
  computed: {},
  created() {
    if (this.historyAnswer.length !== 0) {
      this.answerList = this.historyAnswer;
    } else {
      let num = this.question.emptyCount ? this.question.emptyCount : 0;
      console.log("emptyCount", this.question)
      let list = [];
      if (num !== 0) {
        for (let i = 0; i < num; i++) {
          let obj = {};
          obj[`answer${i + 1}`] = "";
          list.push(obj);
        }
      }
      this.answerList = list;
    }
  },
};
</script>


<style lang="less" scoped>
.stem {
  margin-bottom: 20px;
  .index {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
  }
  .question_title {
    display: inline-block;
    max-width: 90%;
    font-size: 14px;
    color: #333;
    // vertical-align: middle;
    /deep/ img {
      margin: 0 5px !important;
      //   width: 100%;
      //   max-height: 60px !important;
    }
  }
  .question_score {
    display: inline-block;
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    vertical-align: top;
  }
}
.question_answer_content_sty {
  width: 100%;
  .question_answer_content_item_sty {
    width: 100%;
    height: 38px;
    &:not(:first-child) {
      margin-top: 20px;
    }
    & > div {
      display: inline-block;
      vertical-align: top;
    }
    .question_answer_content_item_index_sty {
      width: 41px;
      height: 100%;
      background: #e6e6e6;
      text-align: center;
      line-height: 38px;
    }
    .question_answer_content_item_input_sty {
      width: 859px;
      height: 38px;
      line-height: 38px;
      position: relative;
      border: 1px solid #e6e6e6;
      & > input {
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
      }
      /deep/ .el-input__inner {
        border: none !important;
        height: 34px;
        line-height: 34px;
      }
      & > i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #bdbdbd;
      }
    }
  }
}
</style>